.alert {
  text-align: center;
  padding: 5px;
  background-color: #fc0;
}

/* Overrides of WordPress styles. */

/* Break-points from the WP theme. */
@media (max-width: 1920px) {

}

@media ( max-width: 1280px ) {

}

@media (max-width: 800px) {
  a#logo img, .safari a#logo img {
    max-height: 75%;
  }

}

@media (max-width: 780px) {

}

/* This is the transition to the hamburger menu. */
@media (max-width: 640px) {

  /* Let the branding bar shrink more for the map page. */
  #branding {
    min-height: 80px;
  }
  #bg_image {
    min-height: 80px;
  }

}

@media (max-width: 400px) {
  /* Let the branding bar shrink more for the map page. */
  #branding {
    min-height: 60px;
  }
  #bg_image {
    min-height: 60px;
  }
}
